import $ from "jquery";
import Typed from 'typed.js';


class App {
    run() {
        this.test()
        //this.menu()
        this.reveal()
        this.text()
    }

    test() {
        console.log("It works!");
    }

    text() {

        if ($("#typed").length > 0) {
            var typed = new Typed("#typed", {
                stringsElement: '#typed-strings',
                typeSpeed: 100,
                backSpeed: 30,
                backDelay: 500,
                startDelay: 1000,
                loop: true,
            });
        }
        if ($("#typed1").length > 0) {
            var typed1 = new Typed("#typed1", {
                stringsElement: '#typed-strings1',
                typeSpeed: 100,
                backSpeed: 30,
                backDelay: 500,
                startDelay: 1000,
                loop: true,
            });
        }
    }

    menu() {
        /**
         * Burger Menu animation
         */

        $('.burger-menu__icon').click(function () {
            $(this).toggleClass('open');
            $('.burger-menu_list').toggleClass('open-burger');
        });


        $('.read-more').click(function () {
            $(this).parent().find(".team-description").toggleClass('open-more');
        })
    }

    reveal() {

        // reveal items while scrolling the page
        var winWidth = $(window).width();
        var winHeight = $(window).height();
        var tollerance = winHeight / 5;

        if (winWidth < 768) {
            tollerance = 0;
        }

        function reveal() {

            var count = 1;

            $('.reveal.not-revealed').each(function () {
                var $this = $(this);

                if ($(document).scrollTop() + winHeight - tollerance > $this.offset().top && !$this.attr('revealed')) {

                    setTimeout(function () {
                        $this.removeClass('not-revealed');
                    }, count * 200);

                    count++;
                }
            });
        }

        setTimeout(function () {
            reveal();
        }, 150);

        $(document).scroll(function () {
            reveal();
        });

        $(window).resize(function () {
            reveal();
        });
    }
}


const app = new App();
export default app;
